/* 主题颜色 */
/* 边框与背景颜色 */
/* 成功颜色 */
/* 错误颜色 */
/* 警告颜色 */
/* 垂直居中 */
.fvc {
  display: flex;
  align-items: center;
}
/* 水平居中 */
.fhc {
  display: flex;
  justify-content: center;
}
/* 两极、垂直居中 */
.fsb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* 尾部、垂直居中 */
.fed {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
/* 均匀分布 */
.fsa {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
/* 水平、垂直居中 */
.fc {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* 省略字符 */
.te {
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* 空图片 */
.emptyimg {
  background-image: url('/assets/images/empty.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
html {
  box-sizing: border-box;
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'PingFangSC-Regular', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}
::-webkit-scrollbar-corner {
  background: none;
}
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #80A352;
}
.webkit-scrollbar::-webkit-scrollbar-thumb {
  background: none;
  display: none;
  transition: all 0.1s ease-in-out;
}
.webkit-scrollbar:hover::-webkit-scrollbar-thumb {
  display: block;
  background-color: #80A352;
}
#root {
  height: 100%;
}
/* 禁用 */
.disabled {
  color: #c5c5c5 !important;
  pointer-events: none;
  cursor: not-allowed;
}
/* 链接 */
.link {
  font-weight: 400;
  font-size: 14px;
  color: #80A352;
  cursor: pointer;
}
.link:hover {
  color: #80A3527e;
}
.loading {
  width: 100%;
  height: 100%;
}
.mainLayout {
  height: 100%;
  overflow: auto;
}
.mainLayout::-webkit-scrollbar-thumb {
  background: none;
  display: none;
  transition: all 0.1s ease-in-out;
}
.mainLayout:hover::-webkit-scrollbar-thumb {
  display: block;
  background-color: #80A352;
}
.mainLayout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.mainLayout-header-icon {
  display: inline-block;
  width: 60px;
  height: 60px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  transform: rotateZ(0deg);
}
.mainLayout-header .open {
  background-image: url('/assets/icons/audio_open.png');
  animation: 3s linear infinite rotate;
}
.mainLayout-header .close {
  background-image: url('/assets/icons/audio_close.png');
}
@keyframes rotate {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}
.pageLayout {
  width: 100%;
  height: 100%;
  background: #F5F5FA;
  overflow: hidden;
}
.pageLayout-loading {
  width: 100%;
  height: 100%;
}
.pageLayout-loading > .ant-spin-container {
  display: flex;
}
.pageLayout-left {
  flex-shrink: 0;
  margin-left: 7.8%;
  width: 216px;
  height: 100%;
  overflow: auto;
  transition: width 0.2s ease-in-out;
  border-left: 1px solid #D2D7DC;
  border-right: 1px solid #D2D7DC;
}
.pageLayout-left::-webkit-scrollbar-thumb {
  background: none;
  display: none;
  transition: all 0.1s ease-in-out;
}
.pageLayout-left:hover::-webkit-scrollbar-thumb {
  display: block;
  background-color: #80A352;
}
.pageLayout-left-fold {
  width: 0;
  border-width: 0;
}
.pageLayout-left .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #80A352;
  color: #fff;
}
.pageLayout-right {
  flex: 1;
  width: 100%;
  height: 100%;
}
.pageLayout-right .header {
  display: flex;
  align-items: center;
  padding: 0 4px;
  height: 48px;
  background: #E1E1EB;
}
.pageLayout-right .header-btn {
  margin-right: 16px;
}
.pageLayout-right .header-backitem {
  display: flex;
  align-items: center;
  margin-right: 16px;
  cursor: pointer;
  color: #787A83;
}
.pageLayout-right .header-backitem-icon {
  margin-right: 10px;
  width: 20px;
  height: 16px;
  background: url('/assets/icons/back.svg') 0 0 no-repeat;
}
.pageLayout-right .header-backitem-name {
  font-size: 14px;
  font-weight: 400;
}
.pageLayout-right .header-backitem:hover {
  color: #80A352;
}
.pageLayout-right .header-crumbs {
  margin-right: 16px;
}
.pageLayout-right .cont {
  height: calc(100% - 48px);
  border-radius: 4px;
  overflow: auto;
  padding-right: 7.8%;
}
.pageLayout-right .cont::-webkit-scrollbar-thumb {
  background: none;
  display: none;
  transition: all 0.1s ease-in-out;
}
.pageLayout-right .cont:hover::-webkit-scrollbar-thumb {
  display: block;
  background-color: #80A352;
}
@media screen and (max-width: 1800px) {
  .pageLayout-left {
    margin-left: 0;
  }
  .pageLayout-right .cont {
    padding-right: 0;
  }
}
.notfound {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.notfound-img {
  width: 178px;
  height: 166px;
  background: url('/assets/images/404.png') 0 0 no-repeat;
}
.home .start {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 20px;
}
.home .start-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  margin: 0 50px 25px 50px;
  box-shadow: 0px 10px 25px 0px #EDF2E5;
  border-radius: 15px;
}
.home .start-img {
  width: 213px;
  margin-top: 0px;
  margin-bottom: 30px;
  border-radius: 8px;
}
.home .start-cont {
  font-family: 'PingFangSC-Medium', 'PingFang SC';
  font-size: 12px;
  font-weight: 500;
  color: #204439;
  line-height: 18px;
  letter-spacing: 1px;
  margin-bottom: 20px;
  text-align: justify;
}
.home .start-input {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  width: 205px;
  height: 64px;
  background: #F0F0F0;
  border-radius: 15px 15px 0px 0px;
  border-bottom: 3px solid #7DA352;
  margin-bottom: 20px;
}
.home .start-input .adm-input {
  --placeholder-color: none;
}
.home .start-input .adm-input-element {
  font-size: 14px;
  font-family: 'PingFangSC-Medium', 'PingFang SC';
  font-weight: 500;
  color: #7B7C7C;
  line-height: 14px;
  margin-left: 14px;
  text-align: left;
}
.home .title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'PingFangSC-Semibold', 'PingFang SC';
  margin-top: 20px;
  margin-bottom: 18px;
  padding: 0 40px;
  font-size: 18px;
  font-weight: 800;
  color: #204439;
  line-height: 25px;
  text-align: justify;
}
.home .description {
  margin-bottom: 20px;
  text-align: center;
  font-size: 12px;
  font-family: 'PingFangSC-Semibold', 'PingFang SC';
  font-weight: 600;
  color: #204439;
  line-height: 13px;
}
.home .adm-button {
  width: 170px;
  height: 45px;
  background: linear-gradient(180deg, #C6DEA6 0%, #8EBF64 100%);
  border-radius: 23px;
}
.home .adm-button span {
  font-size: 16px;
  font-family: 'PingFangSC-Medium', 'PingFang SC';
  font-weight: 500;
  color: #FFFFFF;
  line-height: 18px;
  letter-spacing: 1px;
}
.home .options {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 20px;
}
.home .options-cont {
  margin-bottom: 30px;
}
.home .options .option {
  display: flex;
  align-items: center;
  padding: 12.5px 0 0 0;
  flex-direction: column;
  transform: translateY(0);
  transition: transform 0.3s;
  width: 135px;
  height: 203px;
  background: #FFFFFF;
  border-radius: 15px 15px 0px 0px;
  border: 1px solid #EAEBEC;
  border-bottom: 3px solid #7B7C7C;
}
.home .options .option-selected {
  border-color: #80A352;
  box-shadow: 0px 0px 10px 0px #00000082;
  transform: translateY(-2px);
}
.home .options .option-img {
  width: 110px;
  height: 110px;
  border-radius: 15px 15px 0px 0px;
  margin-bottom: 16px;
}
.home .options .option-name {
  padding: 0 10px;
  margin-bottom: 49px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #204439;
  line-height: 14px;
}
.home .answer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
  flex-direction: column;
  padding-bottom: 20px;
}
.home .answer-card {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 90px;
  padding: 16px;
  background: #FFFFFF;
  box-shadow: 0px 10px 25px 0px #EDF2E5;
  border-radius: 15px;
}
.home .answer-card-l {
  width: 45%;
  flex: 2;
  margin-right: 10px;
}
.home .answer-card-r {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 3;
  flex-direction: column;
}
.home .answer-card-name {
  width: 100%;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  text-align: justify;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #204439;
  line-height: 20px;
  letter-spacing: 1px;
}
.home .answer-card-answer {
  width: 100%;
  text-align: justify;
  font-size: 10px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #204439;
  line-height: 13px;
  letter-spacing: 1px;
}
.home .answer-next {
  margin: 40px 0 100px 0;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}
.home .end {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
  flex-direction: column;
  padding-bottom: 20px;
}
.home .end-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #204439;
  line-height: 23px;
}
.home .end-header-name {
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #7DA352;
  line-height: 23px;
  letter-spacing: 1px;
}
.home .end-header-line {
  width: 109px;
  height: 1px;
  border: 2px solid #7DA352;
  margin-top: 5px;
  margin-bottom: 16px;
}
.home .end-desc {
  margin-bottom: 20px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #204439;
  line-height: 13px;
  letter-spacing: 1px;
}
.home .end-card {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 30px 0;
  flex-direction: column;
  padding: 20px 25px 30px;
  background: #FFFFFF;
  box-shadow: 0px 10px 25px 0px #EDF2E5;
  border-radius: 15px;
}
.home .end-card-img {
  width: 90%;
  margin-bottom: 20px;
}
.home .end-card-peerspace {
  margin-top: 20px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #204439;
  line-height: 15px;
  letter-spacing: 1px;
}
.home .end-card .adm-button {
  width: 185px;
}
.home .end-btn {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
.home .end-btn .adm-button {
  width: 121px;
}
.home .end-btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 60px;
  height: 65px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.home .end-btn-icon-text {
  position: absolute;
  bottom: 7px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #4F4F4F;
  line-height: 13px;
  letter-spacing: 1px;
}
.home .end-btn .sharetype {
  background-image: url('/assets/icons/share.png');
}
.home .end-btn .downloadtype {
  background-image: url('/assets/icons/download.png');
}
.home .download {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.home .download-img {
  width: 300px;
}
.home .download-wrapper {
  position: relative;
  width: 300px;
}
.home .download-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
}
.home .download-template {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  width: 300px;
  background: #fff;
  border-radius: 15px;
}
.home .download-template-title {
  font-family: 'PingFangSC-Semibold', 'PingFang SC';
  font-size: 18px;
  font-weight: 800;
  color: #204439;
  line-height: 25px;
}
.home .download-template-img {
  width: 90%;
  margin: 10px 0;
}
.home .download-template-name {
  padding: 5px 0;
  margin-bottom: 10px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #7DA352;
  line-height: 23px;
  letter-spacing: 1px;
  border-bottom: 3px solid #7DA352;
}
.home .download-template-builder {
  margin-bottom: 10px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #204439;
  line-height: 13px;
  letter-spacing: 1px;
}
.home .download-template-desc {
  margin-bottom: 20px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 400;
  color: #204439;
  line-height: 13px;
  letter-spacing: 1px;
}
.home .download-template-footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.home .download-template-footer-img {
  display: inline-block;
  width: 70px;
  height: 60px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.home .download-template-footer .ps {
  margin-right: 40px;
  background-image: url('/assets/images/downloadlogo.png');
}
.home .download-template-footer .qr {
  background-image: url('/assets/images/frontpage.png');
  width: 60px;
  height: 60px;
}
.home .download-hint {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  width: 170px;
  height: 27px;
  background: #FFFFFF;
  border-radius: 23px;
  font-size: 12px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #4F4F4F;
  line-height: 17px;
  letter-spacing: 1px;
}
.home .share {
  position: absolute;
  top: 0;
  right: 0;
  width: 130px;
  height: 156px;
  background: url("/assets/images/share.png") 100% no-repeat;
  background-size: 100% 100%;
}
.home .adm-mask {
  display: flex;
  align-items: center;
  justify-content: center;
}
