html {
    box-sizing: border-box;
    overflow-x: hidden;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'PingFangSC-Regular', 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

ul,
li {
    padding: 0;
    margin: 0;
    list-style: none
}

::-webkit-scrollbar {
    width: 5px;
    height: 10px;
}

::-webkit-scrollbar-corner {
    background: none;
}

::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: @theme-color;
}

.webkit-scrollbar {
    &::-webkit-scrollbar-thumb {
        background: none;
        display: none;
        transition: all .1s ease-in-out;
    }

    &:hover {
        &::-webkit-scrollbar-thumb {
            display: block;
            background-color: @theme-color;
        }
    }
}

#root {
    height: 100%;
}

/* 禁用 */
.disabled {
    color: #c5c5c5 !important;
    pointer-events: none;
    cursor: not-allowed;
}

/* 链接 */
.link {
    font-weight: 400;
    font-size: 14px;
    color: @theme-color;
    cursor: pointer;
}

.link:hover {
    color: @theme-color-hover;
}

.loading {
    width: 100%;
    height: 100%;
}
