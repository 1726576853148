// 布局样式
.mainLayout {
    height: 100%;
    .webkit-scrollbar();
    overflow: auto;

    &-header {
        .fsb();
        padding: 10px;
        &-icon {
            display: inline-block;
            width: 60px;
            height: 60px;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            transform: rotateZ(0deg)
        }
        .open {
            background-image: url('/assets/icons/audio_open.png');
            animation: 3s linear infinite rotate;
        }
        .close {
            background-image: url('/assets/icons/audio_close.png');
        }
    }

    &-cont {
    }

    @keyframes rotate {
        from {
            transform: rotateZ(0deg);
        }
        to {
            transform: rotateZ(360deg);
        }
    }
}
