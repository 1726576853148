.home {
    .start {
        .fc();
        flex-direction: column;
        padding-bottom: 20px;
        &-card {
            .fc();
            flex-direction: column;
            padding: 20px;
            margin: 0 50px 25px 50px;
            box-shadow: 0px 10px 25px 0px #EDF2E5;border-radius: 15px;
        }

        &-img {
            width: 213px;
            margin-top: 0px;
            margin-bottom: 30px;
            border-radius: 8px;
        }

        &-cont {
            font-family: 'PingFangSC-Medium', 'PingFang SC';
            font-size: 12px;
            font-weight: 500;
            color: #204439;
            line-height: 18px;
            letter-spacing: 1px;
            margin-bottom: 20px;
            text-align: justify;
        }

        &-input {
            .fc();
            margin-bottom: 0px;
            width: 205px;
            height: 64px;
            background: #F0F0F0;
            border-radius: 15px 15px 0px 0px;
            border-bottom: 3px solid #7DA352;
            margin-bottom: 20px;
            .adm-input {
                --placeholder-color: none;
                &-element {
                    font-size: 14px;
                    font-family: 'PingFangSC-Medium', 'PingFang SC';
                    font-weight: 500;
                    color: #7B7C7C;
                    line-height: 14px;
                    margin-left: 14px;
                    text-align: left;
                }
            }
        }
    }

    .title {
        .fc();
        font-family: 'PingFangSC-Semibold', 'PingFang SC';
        margin-top: 20px;
        margin-bottom: 18px;
        padding: 0 40px;
        font-size: 18px;
        font-weight: 800;
        color: #204439;
        line-height: 25px;
        text-align: justify;
    }

    .description {
        margin-bottom: 20px;
        text-align: center;
        font-size: 12px;
        font-family: 'PingFangSC-Semibold', 'PingFang SC';
        font-weight: 600;
        color: #204439;
        line-height: 13px;
    }

    .adm-button {
        width: 170px;
        height: 45px;
        background: linear-gradient(180deg, #C6DEA6 0%, #8EBF64 100%);
        border-radius: 23px;
        span {
            font-size: 16px;
            font-family: 'PingFangSC-Medium', 'PingFang SC';
            font-weight: 500;
            color: #FFFFFF;
            line-height: 18px;
            letter-spacing: 1px;
        }
    }

    .options {
        .fc();
        flex-direction: column;
        padding-bottom: 20px;
        &-cont {
            margin-bottom: 30px;
        }
        .option {
            .fvc();
            padding: 12.5px 0 0 0;
            flex-direction: column;
            transform: translateY(0);
            transition: transform .3s;
            width: 135px;
            height: 203px;
            background: #FFFFFF;
            border-radius: 15px 15px 0px 0px;
            border: 1px solid #EAEBEC;
            border-bottom: 3px solid #7B7C7C;
            &-selected {
                border-color: @theme-color;
                box-shadow: 0px 0px 10px 0px #00000082;
                transform: translateY(-2px);
            }
            &-img {
                width: 110px;
                height: 110px;
                border-radius: 15px 15px 0px 0px;
                margin-bottom: 16px;
            }
            &-name {
                padding: 0 10px;
                margin-bottom: 49px;
                font-size: 12px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #204439;
                line-height: 14px;
            }
        }
    }

    .answer {
        .fc();
        padding: 0 50px;
        flex-direction: column;
        padding-bottom: 20px;
        &-card {
            .fc();
            margin-top: 90px;
            padding: 16px;
            background: #FFFFFF;
            box-shadow: 0px 10px 25px 0px #EDF2E5;
            border-radius: 15px;
            &-l {
                width: 45%;
                flex: 2;
                margin-right: 10px;
            }
            &-r {
                .fc();
                flex: 3;
                flex-direction: column;
            }
            &-name {
                width: 100%;
                margin-bottom: 20px;
                font-size: 16px;
                font-weight: 600;
                text-align: justify;
                font-family: PingFangSC-Semibold, PingFang SC;
                color: #204439;
                line-height: 20px;
                letter-spacing: 1px;
            }
            &-answer {
                width: 100%;
                text-align: justify;
                font-size: 10px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #204439;
                line-height: 13px;
                letter-spacing: 1px;
            }
        }
        &-next {
            margin: 40px 0 100px 0;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
        }
    }

    .end {
        .fc();
        padding: 0 50px;
        flex-direction: column;
        padding-bottom: 20px;
        &-header {
            .fc();
            flex-direction: column;
            margin-bottom: 20px;
            font-weight: 600;
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            color: #204439;
            line-height: 23px;
            &-name {
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #7DA352;
                line-height: 23px;
                letter-spacing: 1px;
            }
            &-line {
                width: 109px;
                height: 1px;
                border: 2px solid #7DA352;
                margin-top: 5px;
                margin-bottom: 16px;
            }
        }
        &-desc {
            margin-bottom: 20px;
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #204439;
            line-height: 13px;
            letter-spacing: 1px;
        }
        &-card {
            .fc();
            margin: 0 0 30px 0;
            flex-direction: column;
            padding: 20px 25px 30px;
            background: #FFFFFF;
            box-shadow: 0px 10px 25px 0px #EDF2E5;
            border-radius: 15px;
            &-img {
                width: 90%;
                margin-bottom: 20px;
            }
            &-peerspace {
                margin-top: 20px;
                font-size: 12px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #204439;
                line-height: 15px;
                letter-spacing: 1px;
            }
            .adm-button {
                width: 185px;
            }
        }
        &-btn {
            .fsa();
            width: 100%;
            .adm-button {
                width: 121px;
            }
            &-icon {
                .fc();
                position: relative;
                width: 60px;
                height: 65px;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                &-text {
                    position: absolute;
                    bottom: 7px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #4F4F4F;
                    line-height: 13px;
                    letter-spacing: 1px;
                }
            }
            .sharetype {
                background-image: url('/assets/icons/share.png');
            }
            .downloadtype {
                background-image: url('/assets/icons/download.png');
            }
        }
    }

    .download {
        .fc();
        flex-direction: column;
        width: 100%;
        height: 100%;
        &-img {
            width: 300px;
        }
        &-wrapper {
            position: relative;
            width: 300px;
        }
        &-loading {
            .fc();
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 15px;
        }
        &-template {
            .fc();
            flex-direction: column;
            padding: 20px;
            width: 300px;
            background: #fff;
            border-radius: 15px;
            &-title {
                font-family: 'PingFangSC-Semibold', 'PingFang SC';
                font-size: 18px;
                font-weight: 800;
                color: #204439;
                line-height: 25px;
            }
            &-img {
                width: 90%;
                margin: 10px 0;
            }
            &-name {
                padding: 5px 0;
                margin-bottom: 10px;
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #7DA352;
                line-height: 23px;
                letter-spacing: 1px;
                border-bottom: 3px solid #7DA352;
            }
            &-builder {
                margin-bottom: 10px;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #204439;
                line-height: 13px;
                letter-spacing: 1px;
            }
            &-desc {
                margin-bottom: 20px;
                font-size: 12px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 400;
                color: #204439;
                line-height: 13px;
                letter-spacing: 1px;
            }
            &-footer {
                .fsa();
                &-img {
                    display: inline-block;
                    width: 70px;
                    height: 60px;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }
                .ps {
                    margin-right: 40px;
                    background-image: url('/assets/images/downloadlogo.png')
                }
                .qr {
                    background-image: url('/assets/images/frontpage.png');
                    width: 60px;
                    height: 60px;
                }
            }
        }
        &-hint {
            .fc();
            margin-top: 16px;
            width: 170px;
            height: 27px;
            background: #FFFFFF;
            border-radius: 23px;
            font-size: 12px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #4F4F4F;
            line-height: 17px;
            letter-spacing: 1px;
        }
    }

    .share {
        position: absolute;
        top: 0;
        right: 0;
        width: 130px;
        height: 156px;
        background: url("/assets/images/share.png")
            100% no-repeat;
        background-size: 100% 100%;
    }
    .adm-mask {
        .fc();
    }
}