//公共样式


/* 垂直居中 */
.fvc {
    display: flex;
    align-items: center;
}

/* 水平居中 */
.fhc {
    display: flex;
    justify-content: center;
}

/* 两极、垂直居中 */
.fsb {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* 尾部、垂直居中 */
.fed {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

/* 均匀分布 */
.fsa {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

/* 水平、垂直居中 */
.fc {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* 省略字符 */
.te {
    white-space: nowrap;
    word-wrap: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* 空图片 */
.emptyimg {
    background-image: url('/assets/images/empty.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
