.pageLayout {
    width: 100%;
    height: 100%;
    background: #F5F5FA;
    overflow: hidden;
    &-loading {
        width: 100%;
        height: 100%;
    }
    &-loading>.ant-spin-container {
        display: flex;
    }
    &-left {
        .webkit-scrollbar();
        flex-shrink: 0;
        margin-left: 7.8%;
        width: 216px;
        height: 100%;
        overflow: auto;
        transition: width .2s ease-in-out;
        border-left: 1px solid #D2D7DC;
        border-right: 1px solid #D2D7DC;
        &-fold {
            width: 0;
            border-width: 0;
        }
        .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
            background: @theme-color;
            color: #fff;
        }
    }
    &-right {
        flex: 1;
        width: 100%;
        height: 100%;
        .header {
            .fvc();
            padding: 0 4px;
            height: 48px;
            background: #E1E1EB;

            &-btn {
                margin-right: 16px;
            }

            &-backitem {
                .fvc();
                margin-right: 16px;
                cursor: pointer;
                color: #787A83;
                &-icon {
                    margin-right: 10px;
                    width: 20px;
                    height: 16px;
                    background: url('/assets/icons/back.svg') 0 0 no-repeat;
                }
                &-name {
                    font-size: 14px;
                    font-weight: 400;
                }
            }
            &-backitem:hover {
                color: @theme-color;
            }

            &-crumbs {
                margin-right: 16px;
            }
        }
        .cont {
            .webkit-scrollbar();
            height: calc(100% - 48px);
            border-radius: 4px;
            overflow: auto;
            padding-right: 7.8%;
        }
    }
}

@media screen and (max-width: 1800px) {
    .pageLayout-left {
        margin-left: 0;
    }
    .pageLayout-right .cont {
        padding-right: 0;
    }
}