.notfound {
    .fc();
    flex-direction: column;
    width: 100%;
    height: 100%;

    &-img {
        width: 178px;
        height: 166px;
        background: url('/assets/images/404.png') 0 0 no-repeat;
    }
}